.dashBoardForm {
    margin-top: 3vh;
}
.header-style  {
    background-color: #BFC9CA;
    font-size: 2vh;
    border-style: groove;
    display: block;

}
.header-style-level1  {
    background-color: #AEB6BF;
    font-size: 2vh;
}
.dashboardContainer {
    width: 99%;
    border-style: groove;
    border-radius: 1vh;
    height: 10vh;
    margin-left: 1vh;
    margin-bottom: 2vh;
}
.dashboardGrid {
    border-style: groove;
    width: 99%;
    margin-left: 1vh;
    margin-right: -5vh;
    min-height: 67vh;
    overflow: scroll;
    background-color: light
}
.formSelect{
    overflow-x: scroll;
    height: 4vh;
    width: 22vh;
    text-align: center ;
}
.formInput {
    min-width: 50vh;
    text-align: center;
    height: 4vh
}
.sectionTitle{
    padding-bottom: 20px;
    font-weight: bolder;
    font-size: 19px;
}

.grid-container {
    overflow: auto;
    width: 100%;
}
.eds-app-container {
    padding: 20px 56px;

}
.tableLayout .eds-table-heading {
    padding: var(--eds-space-8);
    color: black;
    font-weight: bolder;
    min-width: 120px;
}
.tableLayout .eds-table-heading:first-of-type {
    padding-left: var(--eds-space-16);
}
.tableLayout .eds-table-cell {
    padding: var(--eds-space-8);
}
.tableLayout .eds-table-cell.col-text-align-right span{
    text-align: right;
}
.tableLayout .eds-table-cell:first-of-type {
    padding-left: var(--eds-space-16);
}

.eds-vertical-navbar__strip ul {
    overflow: auto;
}
.eds-scrim .eds-modal__content {
    overflow: auto !important;
}
.processing-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000cc;
    z-index: 100;
}
.processing-container .processing-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    width: 200px;
    height: 100px;
    padding: 10px 20px;
    font-weight: bold;
}

@media screen and (min-height: 10px) and (max-height: 4000px) {
    .dashboardContainer {
        overflow-x: scroll;
        width: 153vh;
        border-style: groove;
        border-radius: 1vh;
        height: 10vh;
        margin-top: 3vh;
        margin-left: 10vh;
        margin-bottom: 2vh
    }
    .dashboardGrid {
        border-style: groove;
		width: 153vh;
        margin-left: 10vh;
        height: 70vh;
        overflow: scroll;
        background-color: light
    }
    .css-18lolh3-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        overflow: scroll;
    }

}

@media screen and (min-height: 10px) and (max-height: 4000px) {
    .dashboardContainer {
        width: 85%;
        border-style: groove;
        border-radius: 1vh;
        height: 15vh;
        margin-left: 25vh;
        margin-right: 15vh;
        margin-top: 5vh;
        padding-top: 2.5vh;
        margin-bottom: 2vh
    }
    .dashboardGrid {
        width: 85%;
        margin-left: 25vh;
        margin-right: 15vh;
        height: 100vh;
        overflow-x: scroll;
        background-color: light
    }
    .css-18lolh3-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
        font-size: medium;
    }
    .formSelect{
        height: 7vh;
        width: 40vh;
    }
    .dashBoardForm {
        margin-top: 1vh;
        padding-left: 35vh;
    }
    .formInput {
        height: 7vh;
        width: 60vh;
    }
    .paginationCaretLeft {
        vertical-align: sub;
        margin-left: 48px;
    }
    .paginationCaretRight {
        vertical-align: sub;
        margin-left: 36px;
        margin-right: 48px;
    }
    .pageStyle{
        display: inline-block;
        color: #757575;
        margin-left: 36px;
        cursor: pointer;
    }
    .paginationUnderlineStyle {
        border-bottom: 2px solid;
        color: var(--eds-color-text-default);
    }
    .selectRows{
        margin-left: 3px;
        padding: 7px 4px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 60px;
    }
    /* &:before{
        border-bottom: none !important
    } */
    .tableLayout {
        width: 100%;
        overflow: hidden;
        border-radius: 1px;
        scrollbar-width: 1px;
    }
}